<template>
  <b-container class="col px-5">
    <b-row class="d-flex justify-content-center">
      <b-col cols="5 text-center">
        <b-link to="/Franchise-order">
          <b-card class="bg-card">
            <b-card-body>
              <feather-icon
                icon="BriefcaseIcon"
                size="150"
                color="white"
                class="my-3"
              />
              <h1 class="text-white">Franchise</h1>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>

      <b-col cols="5 text-center">
        <b-link to="bulk-order">
          <b-card class="bg-card">
            <b-card-body>
              <feather-icon
                icon="GridIcon"
                size="150"
                color="white"
                class="my-3"
              />
              <h1 class="text-white">Bulk</h1>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col cols="5 text-center">
        <b-link to="/singapore-order">
          <b-card class="bg-card">
            <b-card-body>
              <feather-icon
                icon="FlagIcon"
                size="150"
                color="white"
                class="my-3"
              />
              <h1 class="text-white">Singapore</h1>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>

      <b-col cols="5 text-center">
        <b-link to="/dhl-order/create">
          <b-card class="bg-card">
            <b-card-body>
              <feather-icon
                icon="TruckIcon"
                size="150"
                color="white"
                class="my-3"
              />
              <h1 class="text-white">DHL</h1>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BCard, BCardText, BContainer, BLink, BCol, BRow } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BContainer,
    BLink,
    BRow,
  },
};
</script>

<style scoped>
.bg-card {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(219, 29, 108, 1) 0%,
    rgba(219, 29, 108, 1) 100%
  );
}
</style>
